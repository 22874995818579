*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Login_html {
  font-size: 16px;
}
@font-face {
  font-family: regular;
  src: url("../../../../assets/fonts/myriadpro-regular.ttf");
}
@font-face {
  font-family: light;
  src: url("../../../../assets/fonts/myriadpro-light.ttf");
}
.container {
  background: #f4f7f9;
  display: flex;
  justify-content: space-between;
}
.logoImg {
  margin: 10px 0 0 20px;
}
.imgCreate {
  margin: 50px 0 0 50px;
}
.create {
  margin: 0 80px 0 0;
  width: 38em;
  padding: 60px 60px 0 60px;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
}
.create__title {
  font-family: regular;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #3b8098;
}
.create__title__text {
  font-family: regular;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #6a6a6a;
  padding-top: 10px;
  padding: 10px 0 10px 0;
}
.divider {
  width: 22em;
  background-color: #c4c4c4;
  height: 1px;
  border: none;
  margin: 0 auto;
}
.inputs {
  margin-top: 3em;
}
.input__gmail {
  width: 30em;
  margin-bottom: 5px;
}
.create__text {
  margin-bottom: 60;
  font-family: regular;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #6a6a6a;
}
.create__text__color {
  color: #3b8098;
}
.footer_regis {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 50px;
}
.register {
  width: 10em;
  background-color: #3b8098;
  border-radius: 25px;
  height: 50px;
  color: #ffffff;
  font-size: 22px;
  font-family: regular;
  line-height: 26.4px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: -70px;
  margin-top: 25px;
}
.links {
  right: 5%;
  z-index: 3;
  bottom: 50px;
  margin-bottom: 200px;
  margin: 0 auto;
  width: 12em;
  /* margin-top: 5em; */
}
.links__div {
  display: flex;
  align-items: center;
}
.imgLink {
  width: 30px;
  height: 30px;
}
